<template>
  <div>
    <order>

    </order>
  </div>
</template>
<script>
  import Order from './Order'

  export default {
    components: {
      Order,
    },
  }
</script>
